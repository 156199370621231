import { h, Component } from "preact";
import debounce from "lodash.debounce";
import { PickDoc } from "../pickdoc";
import Outline from "./outline";
import styles from "../styles/content.css";

const mailIcon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6665 3.33331H3.33317C2.4165 3.33331 1.67484 4.08331 1.67484 4.99998L1.6665 15C1.6665 15.9166 2.4165 16.6666 3.33317 16.6666H16.6665C17.5832 16.6666 18.3332 15.9166 18.3332 15V4.99998C18.3332 4.08331 17.5832 3.33331 16.6665 3.33331ZM16.6665 6.66665L9.99984 10.8333L3.33317 6.66665V4.99998L9.99984 9.16665L16.6665 4.99998V6.66665Z"
      fill="#6600FF"
    />
  </svg>
);

export default class Content extends Component {
  constructor(props) {
    super(props);
    this.state = { outline: [] };
    this.resizeAllInputs = this.resizeAllInputs.bind(this);
    this.onResize = debounce(this.resizeAllInputs, 200);
  }

  componentDidMount() {
    const container = document.getElementById("doc-root");
    const pickdoc = new PickDoc(container, this.props.doc);
    this.setState({ outline: pickdoc.outline() });
    pickdoc.addListener("change", data => {
      this.setState({ outline: pickdoc.outline() });
      this.props.onChange(data);
    });

    window.addEventListener("resize", this.resizeAll);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeAllInputs);
    this.onResize.cancel();
  }

  resizeAllInputs() {
    if (window["Stretchy"]) {
      window.Stretchy.resizeAll(document.querySelectorAll("input.text-input"));
    }
  }

  renderMenu() {
    const { showMenu, onCancel } = this.props;
    const style = showMenu ? styles.disableScroll : styles.enableScroll;

    document.getElementsByTagName("body")[0].setAttribute("class", style);

    return (
      <div
        class={`${styles.outlineColumn} ${styles.menu} ${showMenu &&
          styles.showMenu}`}
      >
        <button class={styles.closeBtn} onClick={onCancel}>
          &times;
        </button>
        <nav
          onclick={onCancel}
          class={`${styles.outlineColumn} ${styles.showOutline}`}
        >
          <Outline list={this.state.outline} activeId="TODO" />
          <div class={styles.contact}>
            {mailIcon}
            <a href="mailto:hello+rfp@postlight.com">hello@postlight.com</a>
          </div>
        </nav>
      </div>
    );
  }

  render() {
    const { pinOutline } = this.props;
    const pinned = pinOutline ? styles.fixTop : "";
    const showScrollbar = window.innerHeight < 770;

    return (
      <div>
        {this.renderMenu()}
        <section class={styles.main}>
          <nav
            class={`${styles.outlineColumn} ${pinned} ${showScrollbar &&
              styles.showScrollbar}`}
          >
            <div>
              <Outline list={this.state.outline} activeId="TODO" />
              <div class={styles.contact}>
                {mailIcon}
                <a href="mailto:hello+rfp@postlight.com">hello@postlight.com</a>
              </div>
            </div>
          </nav>
          <div id="doc-root" class={styles.docColumn} />
        </section>
      </div>
    );
  }
}

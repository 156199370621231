import EventEmitter from "eventemitter3";

export class PickDoc extends EventEmitter {
  constructor(container, state) {
    super();
    this.container = container;
    this.outline = this.outline.bind(this);
    this.container.innerHTML = renderBlocks(state.blocks, state.groups);
    attachBlock(this.container, state, update => this.emit("change", update));

    if (window["Stretchy"]) {
      resizeAllInputs();
      setTimeout(() => {
        window.Stretchy.resizeAll(
          document.querySelectorAll("input.text-input")
        );
      }, 250);
    }
  }

  outline() {
    return extractOutline(this.container);
  }
}

function resizeAllInputs() {
  if (window["Stretchy"]) {
    window.Stretchy.resizeAll(document.querySelectorAll("input.text-input"));
  }
}

// Assemble html string to be inserted into block's span
function renderBlocks(blocks, state, str = "") {
  blocks.forEach(({ id, condition, block, children }, idx) => {
    if (condition) {
      str += `<span id="${id}">`;
    }
    if (condition == null || state[condition.group].value === condition.value) {
      str += block;
      if (children) {
        str = renderBlocks(children, state, str);
      }
    }
    if (condition) {
      str += `</span>`;
    }
  });
  return str;
}

// Add event listeners to groups already rendered on the page
function attachBlock(el, state, dispatch) {
  el.querySelectorAll(".group-picker").forEach(group => {
    const { key } = group.dataset;
    const curr = state.groups[key].value;
    if (group.innerHTML !== curr) {
      group.innerHTML = state.groups[key].options[curr];
    }
    group.addEventListener("click", groupPickerClick(state, dispatch));
    group.addEventListener("keydown", groupPickerKeydown);
    group.addEventListener("keyup", groupPickerKeyup(state, dispatch));
  });
  el.querySelectorAll(".local-picker").forEach(local => {
    const curr = state.picks[local.dataset.key].value;
    if (local.innerHTML !== curr) {
      local.innerHTML = curr;
    }
    local.addEventListener("click", localPickerClick(state, dispatch));
    local.addEventListener("keydown", localPickerKeydown);
    local.addEventListener("keyup", localPickerKeyup(state, dispatch));
  });
  el.querySelectorAll(".text-input").forEach(input => {
    const curr = state.inputs[input.dataset.key].value;
    if (input.placeholder !== curr) {
      input.value = curr;
    }
    input.addEventListener("input", inputChange(state, dispatch));
    input.addEventListener("paste", inputPaste);
    input.addEventListener("keydown", inputKeyDown);
  });
  el.querySelectorAll(`.check-row`).forEach(row => {
    const input = row.querySelector(`input[type="checkbox"]`);
    const curr = state.checkboxes[row.dataset.key].checked;
    input.checked = curr;
    input.addEventListener(
      "change",
      checkChange(state, row.dataset.key, dispatch)
    );
  });
  updateOutputs(el, state.inputs);
}

// Gather array of all blocks related to a group's key
function groupBlocks(blocks, key) {
  let output = [];
  blocks.forEach(block => {
    if (block.condition && block.condition.group === key) {
      output.push(block);
    }
    if (block.children) {
      output = output.concat(groupBlocks(block.children, key));
    }
  });
  return output;
}

// Remove block content after cleaning up event listeners
function removeBlock(el) {
  while (el.firstChild) el.removeChild(el.firstChild);
}

// Group picker click handler
// 1. update state
// 2. update picker display
// 3. gather list of blocks related to this groups key
// 4. render/remove blocks based on condition
function groupPickerClick(state, dispatch) {
  return e => {
    const picker = e.currentTarget;
    const { key } = picker.dataset;
    const group = state.groups[key];
    const keys = Object.keys(group.options);
    const curr = keys.indexOf(group.value);
    const next = curr < keys.length - 1 ? curr + 1 : 0;
    group.value = keys[next];
    picker.innerHTML = group.options[group.value];

    const blocks = groupBlocks(state.blocks, key);
    blocks.forEach(block => {
      const el = document.getElementById(block.id);
      if (block.condition.value === group.value) {
        el.innerHTML = renderBlocks([block], state.groups);
        attachBlock(document.getElementById(block.id), state, dispatch);
      } else {
        removeBlock(el);
      }
    });
    // createOutline();
    dispatch(state);
    resizeAllInputs();
  };
}

function groupPickerKeydown(e) {
  if (e.key === "Enter" || e.key === "ArrowDown" || e.key === "ArrowUp") {
    e.preventDefault();
  }
}

function groupPickerKeyup(state, dispatch) {
  return e => {
    if (e.key === "Enter" || e.key === "ArrowDown" || e.key === "ArrowUp") {
      const picker = e.currentTarget;
      const { key } = picker.dataset;
      const group = state.groups[key];
      const keys = Object.keys(group.options);
      const curr = keys.indexOf(group.value);
      const next =
        e.key === "Enter" || e.key === "ArrowUp"
          ? curr < keys.length - 1
            ? curr + 1
            : 0
          : curr < 1
          ? keys.length - 1
          : curr - 1;
      group.value = keys[next];
      picker.innerHTML = group.options[group.value];

      const blocks = groupBlocks(state.blocks, key);
      blocks.forEach(block => {
        const el = document.getElementById(block.id);
        if (block.condition.value === group.value) {
          el.outerHTML = renderBlocks([block], state.groups);
          attachBlock(document.getElementById(block.id), state, dispatch);
        } else {
          removeBlock(el);
        }
      });
      // createOutline();
      dispatch(state);
      resizeAllInputs();
    }
  };
}

// Local picker click handler
// 1. update state
// 2. update picker display
function localPickerClick(state, dispatch) {
  return e => {
    const picker = e.currentTarget;
    const pick = state.picks[picker.dataset.key];
    const curr = pick.options.indexOf(pick.value);
    const next = curr < pick.options.length - 1 ? curr + 1 : 0;
    pick.value = pick.options[next];
    picker.innerHTML = pick.value;
    dispatch(state);
  };
}

function localPickerKeydown(e) {
  if (e.key === "Enter" || e.key === "ArrowDown" || e.key === "ArrowUp") {
    e.preventDefault();
  }
}

function localPickerKeyup(state, dispatch) {
  return e => {
    if (e.key === "Enter" || e.key === "ArrowDown" || e.key === "ArrowUp") {
      const picker = e.currentTarget;
      const pick = state.picks[picker.dataset.key];
      const curr = pick.options.indexOf(pick.value);
      const next =
        e.key === "Enter" || e.key === "ArrowUp"
          ? curr < pick.options.length - 1
            ? curr + 1
            : 0
          : curr < 1
          ? pick.options.length - 1
          : curr - 1;
      pick.value = pick.options[next];
      picker.innerHTML = pick.value;
      dispatch(state);
    }
  };
}

// Input change updates state and triggers output updates
function inputChange(state, dispatch) {
  return e => {
    const { key } = e.target.dataset;
    const input = state.inputs[key];
    input.value =
      e.target.value && e.target.value !== ""
        ? e.target.value
        : e.target.placeholder;
    updateOutputs(document, state.inputs, key);
    dispatch(state);
  };
}

// Only paste plain text with new lines removed
function inputPaste(e) {
  e.preventDefault();
  if (e.clipboardData && e.clipboardData.getData) {
    const txt = e.clipboardData.getData("text/plain");
    document.execCommand("insertHTML", false, removeNewLine(txt));
  } else if (window.clipboardData && window.clipboardData.getData) {
    const txt = window.clipboardData.getData("Text");
    if (window.getSelection) {
      const sel = window.getSelection();
      if (sel.getRangeAt && sel.rangeCount) {
        const range = sel.getRangeAt(0);
        range.deleteContents();
        range.insertNode(document.createTextNode(removeNewLine(txt)));
      }
    } else if (document.selection && document.selection.createRange) {
      document.selection.createRange().text = removeNewLine(txt);
    }
  }
}

function removeNewLine(str) {
  return str.replace(/[\n\r]/g, "");
}

// Prevent new line when typing in input
function inputKeyDown(e) {
  if (e.keyCode === 13) e.preventDefault();
}

// Update outputs with latest input values
// Provide key to isolate change from single input
function updateOutputs(el, inputState, key) {
  if (key) {
    el.querySelectorAll(`.text-output[data-key='${key}']`).forEach(output => {
      output.innerHTML = inputState[key].value;
    });
  } else {
    el.querySelectorAll(`.text-output`).forEach(output => {
      output.innerHTML = inputState[output.dataset.key].value;
    });
  }
}

// Checkbox change updates state
function checkChange(state, key, dispatch) {
  return e => {
    const cb = state.checkboxes[key];
    cb.checked = e.currentTarget.checked;
    dispatch(state);
  };
}

function extractOutline(el) {
  let curr;
  const outline = [];
  const headings = el.querySelectorAll("h2, h3, h4");
  headings.forEach(h => {
    if (h.tagName === "H2" || h.tagName === "H3") {
      curr = { id: h.id, title: h.textContent };
      outline.push(curr);
    } else {
      if (!curr.children) {
        curr.children = [];
      }
      curr.children.push({ id: h.id, title: h.textContent });
    }
  });
  return outline;
}

import "unfetch/polyfill";
import { h, Component } from "preact";
import { set, clear } from "idb-keyval";
import Intro from "./intro";
import Content from "./content";
import ActionBar from "./action-bar";
import ExportModal from "./export-modal";
import ResetModal from "./reset-modal";
import { track } from "../utils/analytics";

const EnableMock = false;

export default class RfpBuilder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pinOutline: false,
      showActionBar: false,
      showExport: false,
      showReset: false,
      exportSuccess: false,
      exportFail: false,
      showMenu: false,
      scrolledToMiddle: false
    };
    this.lastScrollY = window.scrollY;
    this.handleScroll = this.handleScroll.bind(this);
    this.update = this.update.bind(this);
    this.promptExport = this.promptExport.bind(this);
    this.promptReset = this.promptReset.bind(this);
    this.cancelExport = this.cancelExport.bind(this);
    this.cancelReset = this.cancelReset.bind(this);
    this.reset = this.reset.bind(this);
    this.export = this.export.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll, false);
    this.update();
  }

  handleScroll() {
    this.lastScrollY = window.scrollY;
    if (!this.rafId) {
      this.rafId = requestAnimationFrame(this.update);
    }
  }

  promptExport() {
    track("event", "click", {
      event_category: "Export",
      event_label: "Export"
    });

    this.setState({
      showReset: false,
      showExport: true,
      exportSuccess: false,
      exportFail: false
    });
  }

  trackMiddle() {
    this.setState({ scrolledToMiddle: true });

    track("event", "scroll", {
      event_category: "Scroll",
      event_label: "Scrolled 50%"
    });
  }

  export({ email, contact, newsletter }) {
    this.setState({ submitting: true });
    track("event", "click", {
      event_category: "Export",
      event_label: "Confirm Export"
    });

    if (EnableMock) {
      setTimeout(() => {
        this.setState({ exportSuccess: true, submitting: false });
      }, 3000);
      return;
    }

    const { block, ...payload } = this.props.doc;
    fetch("/api/save", {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify({
        email,
        contact,
        newsletter,
        state: payload
      })
    })
      .then(result => {
        if (!result.ok) {
          throw new Error(`${result.status} ${result.statusText}`);
        }
        return result;
      })
      .then(() => this.setState({ exportSuccess: true, submitting: false }))
      .catch(err => {
        console.error(err);
        this.setState({ exportFail: true, submitting: false });
      });
  }

  cancelExport() {
    this.setState({ showExport: false });
  }

  promptReset() {
    track("event", "click", {
      event_category: "Reset",
      event_label: "Reset"
    });

    this.setState({
      showReset: true,
      showExport: false
    });
  }

  reset() {
    track("event", "click", {
      event_category: "Reset",
      event_label: "Confirm Reset"
    });

    clear();
    window.location.reload(false);
  }

  cancelReset() {
    this.setState({ showReset: false });
  }

  handleChange(doc) {
    set("state-cache", JSON.stringify(doc))
      .then(() => console.log("saved"))
      .catch(err => console.log("save failed", err));
  }

  update() {
    const { scrolledToMiddle } = this.state;
    const checkHeight = this.lastScrollY > this.introComp.base.offsetHeight;
    const middle =
      (document.getElementById("builder-ui").scrollHeight -
        this.introComp.base.offsetHeight) /
      2;

    if (!scrolledToMiddle && this.lastScrollY > middle) {
      this.trackMiddle();
    }

    this.setState({ showActionBar: checkHeight, pinOutline: checkHeight });
    this.rafId = null;
  }

  onClick() {
    const { showMenu } = this.state;

    this.setState({ showMenu: !showMenu });
  }

  render() {
    const {
      pinOutline,
      showMenu,
      showActionBar,
      showExport,
      exportSuccess,
      exportFail,
      showReset,
      submitting
    } = this.state;

    return (
      <div>
        <Intro ref={comp => (this.introComp = comp)} />
        <Content
          doc={this.props.doc}
          onChange={this.handleChange}
          pinOutline={pinOutline}
          showMenu={showMenu}
          onCancel={this.onClick}
        />
        <ActionBar
          show={showActionBar}
          onExport={this.promptExport}
          onReset={this.promptReset}
          onClick={this.onClick}
        />
        <ExportModal
          show={showExport}
          submitting={submitting}
          success={exportSuccess}
          fail={exportFail}
          onSubmit={this.export}
          onCancel={this.cancelExport}
        />
        <ResetModal
          show={showReset}
          onConfirm={this.reset}
          onCancel={this.cancelReset}
        />
      </div>
    );
  }
}

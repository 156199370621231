import { h, Component } from "preact";
import styles from "../styles/intro.css";

import icon from "../images/rfp-icon.png";

const alphaLogo = (
  <svg
    viewBox="0 0 57 20"
    fill="none"
    class={styles.alpha}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="57" height="20" rx="10" fill="#A863FF" />
    <path
      d="M8.126 14H10.492L11.108 12.138H14.678L15.28 14H17.842L14.342 4.004H11.668L8.126 14ZM11.71 10.178L12.872 6.566H12.914L14.062 10.178H11.71ZM21.0611 14.14C21.5651 14.14 22.1251 14.028 22.5451 13.902V12.152C22.2791 12.18 22.0971 12.208 21.8731 12.208C21.3971 12.208 21.2991 11.97 21.2991 11.648V4.018H18.9751V12.11C18.9751 13.104 19.5071 14.14 21.0611 14.14ZM23.8909 16.646H26.2009V13.342H26.2429C26.6209 13.818 27.3209 14.182 28.0349 14.182C29.6589 14.182 30.9749 13.146 30.9749 11.438V9.212C30.9749 7.504 29.8969 6.482 28.2449 6.482C27.5449 6.482 26.7329 6.832 26.1729 7.518H26.1589V6.636H23.8909V16.646ZM27.4609 12.474C26.8449 12.474 26.2009 12.11 26.2009 11.298V9.604C26.2009 8.582 26.8589 8.274 27.4469 8.274C28.1049 8.274 28.6509 8.694 28.6509 9.464V11.284C28.6509 12.138 28.0349 12.474 27.4609 12.474ZM32.5381 14H34.8481V9.842C34.8481 8.82 35.3521 8.316 36.0381 8.316C36.8921 8.316 37.1721 8.946 37.1721 9.702V14H39.4821V8.904C39.4821 7.266 38.3761 6.482 37.0601 6.482C36.1781 6.482 35.4221 6.832 34.8901 7.602H34.8481V4.018H32.5381V14ZM43.2979 14.154C44.0399 14.154 44.9219 14 45.5379 13.174H45.5939C45.6079 13.23 45.6639 13.944 45.6779 14H47.8759C47.8199 13.86 47.7499 12.936 47.7499 12.376V9.016C47.7499 7.154 46.1119 6.482 44.4179 6.482C42.7379 6.482 41.1419 7.21 41.1419 8.806V9.072H43.1999V8.862C43.1999 8.47 43.5499 8.12 44.2919 8.12C45.0339 8.12 45.4399 8.442 45.4399 9.044V9.618H44.1659C42.6819 9.618 40.8619 10.262 40.8619 11.998C40.8619 13.384 41.8839 14.154 43.2979 14.154ZM44.1939 12.502C43.4799 12.502 43.1719 12.18 43.1719 11.704C43.1719 11.102 43.6619 10.906 44.3339 10.906H45.4399V11.326C45.4399 12.026 45.0479 12.502 44.1939 12.502Z"
      fill="white"
    />
  </svg>
);

export default class Intro extends Component {
  render() {
    return (
      <div class={styles.background}>
        <header class={styles.intro}>
          <h1>
            <div class={styles.title}>
              {alphaLogo}
              RFP Builder
            </div>
            <span class={styles.appmeta}>
              by <a href="https://postlight.com">Postlight</a>
            </span>
          </h1>
          <div class={styles.large}>Your RFP is ready!</div>

          <p>
            We&apos;ve already written your RFP&mdash;it appears below. All you
            need to do is read it over once and customize it to meet your own
            needs.
          </p>

          <p>
            When you&apos;re done you can download a Word document. Give it a
            try. It will save you hours. (There&apos;s no cost, and no
            obligation.)
          </p>

          <section class={styles.next}>
            <img src={icon} alt="icon" class={styles.icon} />
          </section>
        </header>
      </div>
    );
  }
}

import { h, Component } from "preact";
import styles from "../styles/modals.css";
import btnStyles from "../styles/buttons.css";
import TailSpinIcon from "../images/tail-spin.svg";

export default class ExportModal extends Component {
  constructor(props) {
    super(props);
    this.state = { email: "", contact: true, newsletter: true };
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.state);
  }

  render() {
    const { show, success, fail, submitting, onCancel } = this.props;

    return (
      <div
        class={`${styles.modal} ${styles.exportModal} ${
          show ? styles.exportModalIsVisible : ""
        }`}
      >
        <button class={styles.closeBtn} onClick={onCancel}>
          &times;
        </button>
        {!(success || fail) && (
          <form onSubmit={e => this.handleSubmit(e)} action="/api/save">
            <fieldset disabled={submitting}>
              <h5>Share your masterpiece</h5>
              <p class="micro">All we need is your email address</p>
              <input
                type="email"
                id="save-email"
                placeholder="jane.doe@email.com"
                class={styles.saveInput}
                value={this.state.email}
                onInput={e => this.setState({ email: e.currentTarget.value })}
              />
              <div class={styles.checkRow}>
                <label class="check-row">
                  <input
                    type="checkbox"
                    value="allow-contact"
                    id="contact-check"
                    class={styles.exportCheckbox}
                    checked={this.state.contact}
                    onChange={e =>
                      this.setState({ contact: e.currentTarget.checked })
                    }
                  />
                  Postlight can contact me and say hello
                  <span class={`${"custom-check"} ${styles.exportCheckbox}`} />
                </label>
              </div>
              <div class={styles.checkRow}>
                <label class="check-row">
                  <input
                    type="checkbox"
                    value="newsletter-signup"
                    id="news-check"
                    class={styles.exportCheckbox}
                    checked={this.state.newsletter}
                    onChange={e =>
                      this.setState({ newsletter: e.currentTarget.checked })
                    }
                  />
                  Keep me in the loop with Postlight's web tech newsletter
                  <span class={`${"custom-check"} ${styles.exportCheckbox}`} />
                </label>
              </div>
              <div class={styles.submitRow}>
                {submitting ? (
                  <div
                    class={`${btnStyles.primary} ${btnStyles.loading} ${
                      btnStyles.export
                    }`}
                  >
                    <TailSpinIcon />
                  </div>
                ) : (
                  <input
                    type="submit"
                    value="Send"
                    class={`${btnStyles.primary} ${btnStyles.export}`}
                    disabled={!validEmail(this.state.email)}
                  />
                )}
              </div>
            </fieldset>
          </form>
        )}
        {success && (
          <section class="save-success">
            <h5>🎉 📨 Check your inbox, RFP on the way!</h5>
            <p class="micro">
              Need a partner over a vendor? Contact us at{" "}
              <a href="mailto:hello+rfp@postlight.com">
                hello+rfp@postlight.com
              </a>
            </p>
          </section>
        )}
        {fail && (
          <section class="save-fail">
            <h5>🙁Something went wrong</h5>
            <p class="micro">
              Try again in a minute or contact us at{" "}
              <a href="mailto:hello+rfp@postlight.com">
                hello+rfp@postlight.com
              </a>
              .
            </p>
          </section>
        )}
      </div>
    );
  }
}

function validEmail(email) {
  return /^(\S+)@\S+\.\S+$/.test(email);
}

import { h } from "preact";
import styles from "../styles/buttons.css";

export const PrimaryButton = ({ class: className = "", children, onClick }) => (
  <button class={`${styles.primary} ${className}`} onClick={onClick}>
    {children}
  </button>
);

export const SecondaryButton = ({
  class: className = "",
  children,
  onClick
}) => (
  <button class={`${styles.secondary} ${className}`} onClick={onClick}>
    {children}
  </button>
);

import * as Sentry from "@sentry/browser";
import { h, render } from "preact";
import { get, clear } from "idb-keyval";
import RfpBuilder from "./components/rfp-builder";

Sentry.init({
  dsn: "https://a5aa5df36453473fac450c10a1a265d1@sentry.io/1439517"
});

get("state-cache").then(val => {
  let docState = JSON.parse(
    document.getElementById("builder-state").textContent
  );
  if (val) {
    const cachedState = JSON.parse(val);
    if (cachedState.hash === docState.hash) {
      docState = cachedState;
    } else {
      clear();
    }
  }

  const rootElement = document.getElementById("builder-ui");
  if (rootElement.hasChildNodes()) {
    render(
      <RfpBuilder doc={docState} />,
      rootElement,
      rootElement.firstElementChild
    );
  } else {
    render(<RfpBuilder doc={docState} />, rootElement);
  }
});

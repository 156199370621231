import { h } from "preact";
import { track } from "../utils/analytics";

const trackOutline = e => {
  const { value } = e.currentTarget.dataset;

  track("event", "click", {
    event_category: "Nav Bar",
    event_label: value
  });
};

const Outline = ({ list, activeId }) => {
  const items = list.map(item => {
    return (
      <li key={item.id} class={activeId === item.id ? "active" : ""}>
        <a data-value={item.id} onClick={trackOutline} href={`#${item.id}`}>
          {item.title}
        </a>
        {item.children && <Outline list={item.children} />}
      </li>
    );
  });

  return <ul>{items}</ul>;
};

export default Outline;

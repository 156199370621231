import { h } from "preact";
import { PrimaryButton, SecondaryButton } from "./buttons";
import styles from "../styles/modals.css";

const ResetModal = ({ show, onCancel, onConfirm }) => (
  <div className={show ? styles.background : ""}>
    <div
      class={`${styles.modal} ${styles.resetModal} ${
        show ? styles.resetModalIsVisible : ""
      }`}
    >
      <h5>Reset this RFP?</h5>
      <p class="micro">You will lose any preferences you made in this doc.</p>
      <div class={styles.submitRow}>
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <PrimaryButton onClick={onConfirm}>Reset RFP</PrimaryButton>
      </div>
    </div>
  </div>
);

export default ResetModal;

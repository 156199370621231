import { h } from "preact";
import { PrimaryButton, SecondaryButton } from "./buttons";
import styles from "../styles/action-bar.css";

const menuIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    class={styles.icon}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="#767678" />
  </svg>
);

const ActionBar = ({ show, onExport, onReset, onClick }) => (
  <nav class={`${styles.bar} ${show ? styles.show : ""}`}>
    <div class={styles.title}>
      <span onClick={onClick} class={styles.iconContainer}>
        {menuIcon}
      </span>
      <span class={styles.appname}>RFP Builder</span>
      <span class={styles.by}> by Postlight</span>
    </div>
    <div class={styles.actions}>
      <SecondaryButton class={styles.resetBtn} onClick={onReset}>
        Reset <span class="reset-icon" />
      </SecondaryButton>
      <PrimaryButton onClick={onExport}>Export</PrimaryButton>
    </div>
  </nav>
);

export default ActionBar;
